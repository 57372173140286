import { Button, Error, MultiLineInput } from "@letsbit/milkylb";
import React, { useRef } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { ReactComponent as UploadIcon } from "assets/images/icons/upload.svg";
import { messages } from "./messages";
import { GenericUploadProps } from "./types";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { formMessages } from "utils/formMessages";
import FileDropzone from "components/FileDropzone";
import { DropzoneRef } from "react-dropzone";
import {
  INFORMATION_REQUEST_TYPE,
  InformationRequestsReplyBody,
} from "@letsbit/malcolmlb";
import { RejectedReasonWarning } from "../RejectedReasonWarning";

export const GenericUpload: React.FC<GenericUploadProps> = ({
  onSubmit,
  formId,
  className,
  children,
  informationRequest,
}) => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const { handleSubmit, control } = useForm<{
    files: File[];
    comments: string;
  }>({
    mode: "onBlur",
  });
  const { formatMessage } = useIntl();
  const maxLengthResponse = 499;
  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) => {
        const form: InformationRequestsReplyBody = {
          id: informationRequest.id.toString(),
          files: data.files,
          response: data.comments,
        };
        if (!data.files) {
          delete form.files;
        }

        onSubmit(form);
      })}
      className={classNames(
        className,
        "fadeInSimple flex h-full w-full gap-10",
      )}
    >
      <div className="w-1/2">
        {informationRequest && informationRequest.comments && (
          <RejectedReasonWarning informationRequest={informationRequest} />
        )}
        {children}
        <p className="mb-10 text-[--milkylb-color-neutral-600]">
          {informationRequest.message.split("\n").map((line, index, lines) => (
            <>
              {line}
              {index < lines.length - 1 && <br />}
            </>
          ))}
        </p>
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
            maxLength: {
              value: maxLengthResponse,
              message: formatMessage(formMessages.activityMaxLengthError),
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="mb-8">
              {informationRequest.type !== INFORMATION_REQUEST_TYPE.TEXT && (
                <label className="text-lg font-normal text-[--color-neutral-0]">
                  <FormattedMessage {...messages.commentLabel} />
                </label>
              )}
              <MultiLineInput
                placeholder={formatMessage(
                  INFORMATION_REQUEST_TYPE.TEXT
                    ? messages.commentplaceholder
                    : messages.commentDocsplaceholder,
                )}
                value={value}
                onIonInput={onChange}
                maxlength={maxLengthResponse}
                error={!!error}
                messageError={error?.message}
              />
              <label className="flex justify-end justify-end font-normal text-[--color-neutral-0]">
                {value?.length}/{maxLengthResponse}
              </label>
            </div>
          )}
          name="comments"
        />
      </div>
      {informationRequest.type === INFORMATION_REQUEST_TYPE.DOCUMENT && (
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className="w-1/2">
              <div className="h-full">
                <FileDropzone
                  acceptedExtensions={["application/pdf", "image/*"]}
                  files={value}
                  onChange={(files) => onChange(files)}
                  dropzoneRef={dropzoneRef}
                  fileDisplay={true}
                  noClick
                >
                  <div className="flex w-80 flex-col items-center justify-items-center">
                    <UploadIcon className="mb-4" />
                    <h6 className="mb-10 font-['Tomato_Grotesk'] text-lg text-[--milkylb-color-neutral-200]">
                      <FormattedMessage {...messages.dropzonePlaceholder} />
                    </h6>
                    <Button
                      className="mb-2"
                      leftIcon={ArrowIcon}
                      onClick={() => dropzoneRef.current?.open()}
                      type="button"
                    >
                      <FormattedMessage {...messages.uploadButton} />
                    </Button>
                  </div>
                </FileDropzone>
              </div>
              <Error error={!!error?.message} message={error?.message} />
            </div>
          )}
          name="files"
        />
      )}
    </form>
  );
};
